import React from 'react';
import { isMobile } from 'react-device-detect';
import appstore from '../Assets/appstore.svg'

import Firebase from '../Firebase';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import FadeIn from 'react-fade-in';

import LogoSquareWhite from '../Assets/Logo-Light-Square.png'
import PhoneIntro from '../Assets/phone-intro.png'
import Sharing from '../Assets/Sharing.png'
import Watch from '../Assets/Watch.png'
import CustomeLogo from '../Assets/Logo.png'
import Scanner from '../Assets/Scanner.png'
import BioPage from '../Assets/BioPage.png'
import WalletIntro from '../Assets/Wallet-Desktop.png'
import LogoTransparent from '../Assets/logo-transparent.png'

export default class Main extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            page: null,
            userBioData: null
        }
    }

    componentWillMount = () => {
        console.log("username: " + this.props.match.params.username)
        let username = this.props.match.params.username
        if (username !== undefined) {
            Firebase.firestore().collection("USERS").where("username", "==", username).onSnapshot((querySnapshot) => {
                let usernameDoc = querySnapshot.docs[0]
                if (usernameDoc !== undefined) {
                    this.setState({
                        page: "bio",
                        userBioData: usernameDoc.data().bio
                    })
                }
                else {
                    this.setState({
                        page: "bio",
                        userBioData: null
                    })
                }
            })
        }
        else {
            this.setState({ page: "home" })
        }
    }

    appStoreButton = () => {
        return (
            <div style={{ display: "flex" }}>
                <a href={"https://apps.apple.com/sa/app/omnicard-qr-business-card/id1559293527"}>
                    <img src={appstore}
                        alt={"Omnicard"}
                        style={{ height: isMobile ? 60 : 60 }} />
                </a>
            </div>
        )
    }

    header = () => {
        const desktop = () => {
            return (
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", height: "50vh", alignItems: "center", paddingBottom: 100 }}>
                    <img alt="logo" style={{ height: "30%", marginBottom: 80 }}
                        src={LogoSquareWhite}
                    />
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: 600 }}>
                        <Typography variant={"h3"} style={{ color: "#003777", textAlign: "center", fontWeight: "bold" }}>
                            Omnicard: A Modern Business Card
                        </Typography>
                        <br />
                        <Typography variant={"h4"} style={{ color: "#003777", textAlign: "center", fontWeight: "400" }}>
                            Make your introduction stand out with elegant and customizable digital business cards
                        </Typography>
                        <br />
                        {this.appStoreButton()}
                    </div>
                </div>
            )
        }

        const mobile = () => {
            return (
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", height: "80vh", alignItems: "center", padding: 50 }}>
                    <img alt="logo" style={{ height: "30%", marginBottom: 10, marginTop: 50 }}
                        src={LogoSquareWhite}
                    />
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <Typography variant={"h4"} style={{ color: "#003777", textAlign: "center", fontWeight: "bold" }}>
                            Omnicard: A Modern Business Card
                        </Typography>
                        <br />
                        <Typography variant={"h5"} style={{ color: "#003777", textAlign: "center", fontWeight: "400" }}>
                            Make your introduction stand out with elegant and customizable digital business cards
                        </Typography>
                        <br />
                        <br />
                        <br />
                        {this.appStoreButton()}
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                    </div>
                </div>
            )
        }

        if (isMobile) {
            return (mobile())
        }
        else {
            return (desktop())
        }
    }

    phoneIntroImage = () => {
        const desktop = () => {
            return (
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", height: "60vh", backgroundColor: "#F5F6F6", alignItems: "center" }}>
                    <img alt="phone" style={{ height: "100%" }}
                        src={PhoneIntro}
                    />
                </div>
            )
        }

        const mobile = () => {
            return (
                <div style={{ display: "flex", flexDirection: "row", backgroundColor: "pink", height: "80vh" }}>
                    <img alt="phone" style={{ width: "100%", objectFit: "cover" }}
                        src={PhoneIntro}
                    />
                </div>
            )
        }

        if (isMobile) {
            return (mobile())
        }
        else {
            return (desktop())
        }
    }

    briefDescription = () => {
        const desktop = () => {
            return (
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", height: "50vh", alignItems: "center" }}>
                    <Typography variant={"h4"} style={{ textAlign: "center", fontWeight: "400", width: 600, marginBottom: 50 }}>
                        Stand out out from the crowd with Omnicard’s next-generation business card. Share your contact details or documents, using your own futuristic elegant digital card.
                    </Typography>
                    {this.appStoreButton()}
                </div>
            )
        }

        const mobile = () => {
            return (
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", height: "50vh", alignItems: "center" }}>
                    <Typography variant={"h6"} style={{ textAlign: "center", fontWeight: "400", marginBottom: 50 }}>
                        Stand out out from the crowd with Omnicard’s next-generation business card. Share your contact details or documents, using your own futuristic elegant digital card.
                    </Typography>
                    {this.appStoreButton()}
                </div>
            )
        }

        if (isMobile) {
            return (mobile())
        }
        else {
            return (desktop())
        }
    }

    catchPhrases = () => {
        const desktop = () => {
            return (
                <div style={{ display: "flex", flex: 1, flexDirection: "row", justifyContent: "center", alignItems: "center", height: "30vh" }}>
                    <div style={{ display: "flex", flex: 1, flexDirection: "row", alignItems: "center", justifyContent: "center", height: "50vh" }}>
                        <Typography variant={"h2"} style={{ fontWeight: 500 }}>
                            Share&nbsp;
                        </Typography>
                        <Typography variant={"h2"} style={{ color: "#0059C1", fontWeight: 500, textDecoration: "underline" }}>
                            Anything.
                        </Typography>
                    </div>
                    <div style={{ display: "flex", flex: 1, flexDirection: "row", alignItems: "center", justifyContent: "center", height: "30vh" }}>
                        <Typography variant={"h2"} style={{ fontWeight: 500 }}>
                            With&nbsp;
                        </Typography>
                        <Typography variant={"h2"} style={{ color: "#0059C1", fontWeight: 500, textDecoration: "underline" }}>
                            Anyone.
                        </Typography>
                    </div>
                </div>
            )
        }

        const mobile = () => {
            return (
                <div style={{ display: "flex", flex: 1, flexDirection: "column", justifyContent: "center", alignItems: "center", height: "30vh", paddingBottom: 100 }}>
                    <div style={{ display: "flex", flex: 1, flexDirection: "row", alignItems: "center", justifyContent: "center", height: "50vh", marginBottom: 50 }}>
                        <Typography variant={"h4"} style={{ fontWeight: 500 }}>
                            Share&nbsp;
                        </Typography>
                        <Typography variant={"h4"} style={{ color: "#0059C1", fontWeight: 500, textDecoration: "underline" }}>
                            Anything.
                        </Typography>
                    </div>
                    <div style={{ display: "flex", flex: 1, flexDirection: "row", alignItems: "center", justifyContent: "center", height: "30vh" }}>
                        <Typography variant={"h4"} style={{ fontWeight: 500 }}>
                            With&nbsp;
                        </Typography>
                        <Typography variant={"h4"} style={{ color: "#0059C1", fontWeight: 500, textDecoration: "underline" }}>
                            Anyone.
                        </Typography>
                    </div>
                </div>
            )
        }

        if (isMobile) {
            return (mobile())
        }
        else {
            return (desktop())
        }
    }

    sharing = () => {
        const desktop = () => {
            return (
                <div style={{ display: "flex", flex: 1, flexDirection: "row", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                    <div style={{ display: "flex", flex: 1, flexDirection: "row", justifyContent: "center", alignItems: "center", height: "50vh", backgroundColor: "#007577" }}>
                        <img alt="sharing" src={Sharing} height="100%" />
                    </div>
                    <div style={{ display: "flex", flex: 1, flexDirection: "row", justifyContent: "center", alignItems: "center", height: "50vh" }}>
                        <Typography variant={"h5"} style={{ textAlign: "center", fontWeight: "300", width: 400 }}>
                            It could be your work contact card, resume, portfolio, research paper or YouTube channel. Whatever it is, it can be shared via QR to anyone that scans it with their phone camera.
                        </Typography>
                    </div>
                </div>
            )
        }

        const mobile = () => {
            return (
                <div style={{ display: "flex", flexDirection: "column", }}>
                    <div style={{ display: "flex", flexDirection: "row", backgroundColor: "pink", height: "40vh" }}>
                        <img alt="sharing" src={Sharing} style={{ width: "100%", objectFit: "cover" }} />
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", height: "50vh" }}>
                        <Typography variant={"h5"} style={{ textAlign: "center", fontWeight: 300, margin: 50 }}>
                            It could be your work contact card, resume, portfolio, research paper or YouTube channel. Whatever it is, it can be shared via QR to anyone that scans it with their phone camera.
                        </Typography>
                    </div>
                </div>
            )
        }

        if (isMobile) {
            return (mobile())
        }
        else {
            return (desktop())
        }
    }

    watch = () => {
        const desktop = () => {
            return (
                <div style={{ display: "flex", flex: 1, flexDirection: "row", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                    <div style={{ display: "flex", flex: 1, flexDirection: "row", justifyContent: "center", alignItems: "center", height: "50vh" }}>
                        <Typography variant={"h5"} style={{ textAlign: "center", fontWeight: "300", width: 400 }}>
                            Omnicard comes with a watch app, which allows you to access your styled QR cards much faster and simpler when introducing yourself. If you have added your QR card to Apple Wallet, you can also access your card from Apple Wallet on your watch by double-clicking the side button on your Apple Watch, which is the quickest option for accessing your cards and the most convenient.
                        </Typography>
                    </div>
                    <div style={{ display: "flex", flex: 1, flexDirection: "row", justifyContent: "center", alignItems: "center", height: "50vh", backgroundColor: "#003777" }}>
                        <img alt="watch" src={Watch} height="100%" />
                    </div>
                </div>
            )
        }

        const mobile = () => {
            return (
                <div style={{ display: "flex", flexDirection: "column", }}>
                    <div style={{ display: "flex", flexDirection: "row", backgroundColor: "pink", height: "40vh" }}>
                        <img alt="watch" src={Watch} style={{ width: "100%", objectFit: "cover" }} />
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", height: "70vh" }}>
                        <Typography variant={"h5"} style={{ textAlign: "center", fontWeight: 300, margin: 50 }}>
                            Omnicard comes with a watch app, which allows you to access your styled QR cards much faster and simpler when introducing yourself. If you have added your QR card to Apple Wallet, you can also access your card from Apple Wallet on your watch by double-clicking the side button on your Apple Watch, which is the quickest option for accessing your cards and the most convenient.
                        </Typography>
                    </div>
                </div>
            )
        }

        if (isMobile) {
            return (mobile())
        }
        else {
            return (desktop())
        }
    }

    logo = () => {
        const desktop = () => {
            return (
                <div style={{ display: "flex", flex: 1, flexDirection: "row", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                    <div style={{ display: "flex", flex: 1, flexDirection: "row", justifyContent: "center", alignItems: "center", height: "50vh", backgroundColor: "#007577" }}>
                        <img alt="CustomeLogo" src={CustomeLogo} height="100%" />
                    </div>
                    <div style={{ display: "flex", flex: 1, flexDirection: "row", justifyContent: "center", alignItems: "center", height: "50vh" }}>
                        <Typography variant={"h5"} style={{ textAlign: "center", fontWeight: "300", width: 400 }}>
                            You can create a card with your own logo and brand. Moreover, you can create your own unique QR code by customizing it with an image, such as your logo.
                        </Typography>
                    </div>
                </div>
            )
        }

        const mobile = () => {
            return (
                <div style={{ display: "flex", flexDirection: "column", }}>
                    <div style={{ display: "flex", flexDirection: "row", backgroundColor: "pink", height: "40vh" }}>
                        <img alt="CustomeLogo" src={CustomeLogo} style={{ width: "100%", objectFit: "cover" }} />
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", height: "40vh" }}>
                        <Typography variant={"h5"} style={{ textAlign: "center", fontWeight: 300, margin: 50 }}>
                            You can create a card with your own logo and brand. Moreover, you can create your own unique QR code by customizing it with an image, such as your logo.
                        </Typography>
                    </div>
                </div>
            )
        }

        if (isMobile) {
            return (mobile())
        }
        else {
            return (desktop())
        }
    }

    Scanner = () => {
        const desktop = () => {
            return (
                <div style={{ display: "flex", flex: 1, flexDirection: "row", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                    <div style={{ display: "flex", flex: 1, flexDirection: "row", justifyContent: "center", alignItems: "center", height: "50vh" }}>
                        <Typography variant={"h5"} style={{ textAlign: "center", fontWeight: "300", width: 400 }}>
                            You can scan any physical contact card and add it to your contacts effortlessly
                        </Typography>
                    </div>
                    <div style={{ display: "flex", flex: 1, flexDirection: "row", justifyContent: "center", alignItems: "center", height: "50vh", backgroundColor: "#003777" }}>
                        <img alt="Scanner" src={Scanner} height="100%" />
                    </div>

                </div>
            )
        }

        const mobile = () => {
            return (
                <div style={{ display: "flex", flexDirection: "column", }}>
                    <div style={{ display: "flex", flexDirection: "row", backgroundColor: "pink", height: "40vh" }}>
                        <img alt="Scanner" src={Scanner} style={{ width: "100%", objectFit: "cover" }} />
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", height: "30vh" }}>
                        <Typography variant={"h5"} style={{ textAlign: "center", fontWeight: 300, margin: 50 }}>
                            You can scan any physical contact card and add it to your contacts effortlessly
                        </Typography>
                    </div>
                </div>
            )
        }

        if (isMobile) {
            return (mobile())
        }
        else {
            return (desktop())
        }
    }

    bioPage = () => {
        const desktop = () => {
            return (
                <div style={{ display: "flex", flex: 1, flexDirection: "row", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                    <div style={{ display: "flex", flex: 1, flexDirection: "row", justifyContent: "center", alignItems: "center", height: "50vh", backgroundColor: "#007577" }}>
                        <img alt="CustomeLogo" src={BioPage} height="100%" />
                    </div>
                    <div style={{ display: "flex", flex: 1, flexDirection: "row", justifyContent: "center", alignItems: "center", height: "50vh" }}>
                        <Typography variant={"h5"} style={{ textAlign: "center", fontWeight: "300", width: 400 }}>
                            Go beyond business cards, and create your own web page for all your links
                        </Typography>
                    </div>
                </div>
            )
        }

        const mobile = () => {
            return (
                <div style={{ display: "flex", flexDirection: "column", }}>
                    <div style={{ display: "flex", flexDirection: "row", backgroundColor: "pink", height: "40vh" }}>
                        <img alt="CustomeLogo" src={BioPage} style={{ width: "100%", objectFit: "cover" }} />
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", height: "40vh" }}>
                        <Typography variant={"h5"} style={{ textAlign: "center", fontWeight: 300, margin: 50 }}>
                            Go beyond business cards, and create your own web page for all your links
                        </Typography>
                    </div>
                </div>
            )
        }

        if (isMobile) {
            return (mobile())
        }
        else {
            return (desktop())
        }
    }

    WalletIntroText = () => {
        const desktop = () => {
            return (
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", padding: 100, backgroundColor: "#F5F6F6", alignItems: "center" }}>
                    <Typography variant={"h4"} style={{ textAlign: "center", fontWeight: "bold" }}>
                        Compatible with Apple Wallet
                    </Typography>
                    <br />
                    <Typography variant={"h5"} style={{ textAlign: "center", fontWeight: "400" }}>
                        Cards created on Omnicard can be added to your Apple Wallet for quick access. So that instead of opening the Omnicard app when you wish to display your QR, you can just double-click the side button and access your QR card from your Apple Wallet.
                    </Typography>
                    <br />
                    <br />
                    {this.appStoreButton()}
                </div>
            )
        }
        const mobile = () => {
            return (
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", padding: 50, backgroundColor: "#F5F6F6", alignItems: "center" }}>
                    <Typography variant={"h5"} style={{ textAlign: "center", fontWeight: "bold" }}>
                        Compatible with Apple Wallet
                    </Typography>
                    <br />
                    <Typography variant={"h6"} style={{ textAlign: "center", fontWeight: "400" }}>
                        Cards created on Omnicard can be added to your Apple Wallet for quick access. So that instead of opening the Omnicard app when you wish to display your QR, you can just double-click the side button and access your QR card from your Apple Wallet.
                    </Typography>
                    <br />
                    <br />
                    {this.appStoreButton()}
                </div>
            )
        }

        if (isMobile) {
            return (mobile())
        }
        else {
            return (desktop())
        }
    }

    WalletIntroImage = () => {
        const desktop = () => {
            return (
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", height: "60vh", backgroundColor: "#F5F6F6", alignItems: "center" }}>
                    <img alt="WalletIntro" style={{ height: "100%" }}
                        src={WalletIntro}
                    />
                </div>
            )
        }

        const mobile = () => {
            return (
                <div style={{ display: "flex", flexDirection: "row", backgroundColor: "pink", height: "60vh" }}>
                    <img alt="WalletIntro" style={{ width: "100%", objectFit: "cover" }}
                        src={WalletIntro}
                    />
                </div>
            )
        }

        if (isMobile) {
            return (mobile())
        }
        else {
            return (desktop())
        }

    }

    footer = () => {
        const web = () => {
            return (
                <section style={{ display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "black", height: "10vh" }}>
                    <ul>
                        <a style={{ color: "white" }} href="mailto:support@omnicard.app"> For Support, reach out to us on support@omnicard.app</a>
                        <br />
                        <li>
                            <a style={{ color: "white" }}
                                href="https://www.websitepolicies.com/policies/view/abct7p0F">
                                Terms and Conditions
                            </a>
                        </li>
                        <li>
                            <a style={{ color: "white" }}
                                href="https://www.websitepolicies.com/policies/view/KO8GHrjd">
                                Privacy Policy
                            </a>
                        </li>
                    </ul>
                </section>
            )
        }
        const mobile = () => {
            return (
                <section style={{ display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "black", height: "20vh", padding: 10 }}>
                    <ul>
                        <a style={{ color: "white" }} href="mailto:support@omnicard.app"> For Support, reach out to us on support@omnicard.app</a>
                        <br />
                        <li>
                            <a style={{ color: "white" }}
                                href="https://www.websitepolicies.com/policies/view/abct7p0F">
                                Terms and Conditions
                            </a>
                        </li>
                        <li>
                            <a style={{ color: "white" }}
                                href="https://www.websitepolicies.com/policies/view/KO8GHrjd">
                                Privacy Policy
                            </a>
                        </li>
                    </ul>
                </section>
            )
        }
        if (isMobile) {
            return (mobile())
        }
        else {
            return (web())
        }
    }

    home = () => {
        return (
            <div style={{ display: "flex", flexDirection: "column" }}>
                {this.header()}
                <FadeIn>
                    {this.phoneIntroImage()}
                </FadeIn>
                {this.briefDescription()}
                {this.catchPhrases()}
                {this.sharing()}
                {this.watch()}
                {this.logo()}
                {this.Scanner()}
                {this.bioPage()}
                {this.WalletIntroText()}
                {this.WalletIntroImage()}
                {this.footer()}
            </div>
        )
    }

    bio = () => {
        const linkClicked = (clickedLink) => {
            window.location.href = clickedLink.toLowerCase().includes("http") ? clickedLink : "//" + clickedLink
        }

        const invalidLink = () => {
            return (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: "100vh" }}>
                    <img src={LogoSquareWhite}
                        alt={"Omnicard"}
                        style={{ maxHeight: "40%", maxWidth: "40%" }} />
                    <br />
                    <Typography variant={"subtitle1"} style={{ color: "#003777", textAlign: "center", fontWeight: "bold" }}>
                        Invalid link, please check for typos.
                    </Typography>
                </div>
            )
        }

        const intro = () => {
            const hexToRgb = (hex) => {
                var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
                return result ? parseInt(result[1], 16).toString() + ", " + parseInt(result[2], 16).toString() + ", " + parseInt(result[3], 16).toString() : "155, 155, 155";
            }

            return (
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    {
                        this.state.userBioData.profileImageUrl !== undefined
                        &&
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: 150,
                            width: 150,
                            backgroundSize: "cover",
                            marginBottom: 20
                        }}
                        >
                            <img alt="profile_img"
                                style={{
                                    height: "100%",
                                    width: "100%",
                                    objectFit: "cover",
                                    borderRadius: "50%",
                                    boxShadow: "1px 3px 1px rgba(0, 0, 0, 0.2)"
                                }}
                                src={this.state.userBioData.profileImageUrl}
                            />
                        </div>
                    }
                    {
                        this.state.userBioData.headline !== undefined
                        &&
                        this.state.userBioData.subheadline !== undefined
                        &&
                        <div style={{
                            backgroundColor: this.state.userBioData.introTextHasBackground ? "rgba(" + hexToRgb("#" + this.state.userBioData.introTextBackgroundColor) + ", " + this.state.userBioData.introTextBackgroundOpacity + ")" : null,
                            padding: 5,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                        >
                            {
                                this.state.userBioData.headline !== undefined
                                &&
                                <Typography variant={"h4"} style={{ padding: 5, color: "#" + this.state.userBioData.headlineColor, textAlign: "center", fontWeight: "bold", textShadow: "1px 1px 1px rgba(0, 0, 0, 0.1)" }}>
                                    {this.state.userBioData.headline}
                                </Typography>
                            }
                            {
                                this.state.userBioData.subheadline !== undefined
                                &&
                                <Typography variant={"h7"}
                                    style={{
                                        padding: 5,
                                        color: "#" + this.state.userBioData.subheadlineColor,
                                        textAlign: "center",
                                        fontWeight: "bold",
                                        textShadow: "1px 1px 1px rgba(0, 0, 0, 0.1)"
                                    }}
                                >
                                    {this.state.userBioData.subheadline}
                                </Typography>
                            }
                        </div>
                    }
                </div>
            )
        }

        const buttons = () => {
            return (
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    {
                        this.state.userBioData.links.map((entry) => {
                            return (
                                <div style={{
                                    height: 50,
                                    width: "70vw",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    backgroundColor: "#" + this.state.userBioData.buttonsColor,
                                    marginBottom: 15,
                                    borderRadius: 30,
                                    boxShadow: "1px 3px 1px rgba(0, 0, 0, 0.5)"
                                }}
                                    onClick={() => { linkClicked(entry.link) }}>
                                    <Typography variant={"subtitle1"} style={{ color: "#" + this.state.userBioData.buttonTextColor, textAlign: "center", fontWeight: "bold" }}>
                                        {entry.text}
                                    </Typography>
                                </div>
                            )
                        })
                    }
                </div>
            )
        }

        const backgroundStyle = () => {
            if (this.state.userBioData.backgroundImageAspectRatio === "tile") {
                return {
                    display: "flex",
                    flexDirection: "column",
                    paddingTop: 100,
                    height: "100vh",
                    backgroundImage: "url(" + this.state.userBioData.backgroundImageUrl + ")",
                    backgroundPosition: "center",
                    backgroundSize: "contain"
                }
            }
            else if (this.state.userBioData.backgroundImageAspectRatio === "fit") {
                return {
                    display: "flex",
                    flexDirection: "column",
                    paddingTop: 100,
                    height: "100vh",
                    backgroundImage: "url(" + this.state.userBioData.backgroundImageUrl + ")",
                    backgroundPosition: "center",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat"
                }
            }
            else {
                return {
                    display: "flex",
                    flexDirection: "column",
                    paddingTop: 100,
                    height: "100vh",
                    backgroundImage: "url(" + this.state.userBioData.backgroundImageUrl + ")",
                    backgroundPosition: "center",
                    backgroundSize: "cover"
                }
            }
        }

        if (this.state.userBioData != null) {
            return (
                <div style={backgroundStyle()}>
                    {intro()}
                    <br />
                    {buttons()}
                </div>
            )
        }
        else {
            return (invalidLink())
        }
    }

    loading = () => {
        return (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: "100vh" }}>
                <img src={LogoSquareWhite}
                    alt={"Omnicard"}
                    style={{ maxHeight: "40%", maxWidth: "40%" }} />
                <br />
                <CircularProgress />
            </div>
        )
    }

    render = () => {
        if (this.state.page === "home") {
            return (this.home())
        }
        else if (this.state.page === "bio") {
            return (
                <div>
                    {
                        this.bio()
                    }
                    <div style={{ display: "flex", flexDirection: "column", backgroundColor: "black", alignItems: "center", }}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                            <Typography variant={"subtitle2"}
                                style={{
                                    color: "#" + this.state.userBioData.buttonTextColor,
                                    textAlign: "center",
                                    fontWeight: "bold"
                                }}>
                                Powered by Omnicard app
                            </Typography>
                            <img alt="logo"
                                style={{ marginLeft: 5, height: 30 }}
                                src={LogoTransparent}
                            />
                        </div>
                        <a href={"https://apps.apple.com/sa/app/omnicard-qr-business-card/id1559293527"}>
                            <img src={appstore}
                                alt={"Omnicard"}
                                style={{ height: 40 }} />
                        </a>
                    </div>
                </div>)
        }
        else {
            return (this.loading())
        }
    }
}