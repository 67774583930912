import React from 'react';
import Firebase from '../Firebase';
import LogoSquareWhite from '../Assets/Logo-Light-Square.png'
import 'firebase/firestore';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

export default class Redirect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            link: null,
        }
    }

    componentWillMount = () => {
        Firebase.firestore().collection("LINKS").doc(this.props.match.params.id).get()
            .then((doc) => {
                this.setState({ link: doc.data().longUrl })
                window.location.href = doc.data().longUrl
            })
            .catch((error) => {
                console.log(error)
            })
    }

    render() {
        return (
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100vh", widows: "100vw" }}>
                <img src={LogoSquareWhite}
                        alt={"Omnicard"}
                        style={{ maxHeight: "40%", maxWidth: "40%" }} />
                <br/>
                <CircularProgress style={{ paddingBottom: 20, paddingTop: 20 }}/>
                <br/>
                <Typography variant="h5">Loading link...</Typography>
                <br/>
                <a href={this.state.link}>Click here if stuck.</a>
            </div>
        )
    }
}