import firebase from 'firebase';
import 'firebase/firestore'
import 'firebase/auth'


const Firebase = firebase.initializeApp({
    apiKey: "AIzaSyBPy185PzRbjIsns40lJg-10-Aa5BEdMhw",
    authDomain: "omnicard-app.firebaseapp.com",
    projectId: "omnicard-app",
    storageBucket: "omnicard-app.appspot.com",
    messagingSenderId: "74024407765",
    appId: "1:74024407765:web:bc2147e5e6e1d38014885c",
    measurementId: "G-PHNZSLS3KK"
});


export default Firebase;