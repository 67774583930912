import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import './index.css';
import Terms from './Components/Terms';
import Redirect from './Components/Redirect';
import Admin from './Components/Admin'
import Main from './Components/Main'


function App() {
  return (
    <Router>
      <Switch>
        <Route path="/c/:id" component={Redirect} />
        <Route path="/Terms" component={Terms} />
        <Route path="/Admin" component={Admin} />
        <Route path="/:username?" component={Main} />
      </Switch>
    </Router>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
