import React from 'react';
import Logo from '../Assets/omnicard-logo-h.png'

export default class Terms extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100vh", widows: "100vw" }}>
                <img src={Logo}
                    alt={"Omnicard"}
                    style={{ maxHeight: "30%", maxWidth: "30%" }} />
                <br />
                <ul>
                    <li>
                        <a style={{ color: "black" }}
                            href="https://www.websitepolicies.com/policies/view/abct7p0F">
                            Terms and Conditions
                        </a>
                    </li>
                    <li>
                        <a style={{ color: "black" }}
                            href="https://www.websitepolicies.com/policies/view/KO8GHrjd">
                            Privacy Policy
                        </a>
                    </li>
                </ul>
                <br />
            </div>
        )
    }
}