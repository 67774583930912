import React from 'react';
import LogoTransparent from '../Assets/logo-transparent.png'
import Typography from '@mui/material/Typography';
import Logo from '../Assets/omnicard-logo-h.png'
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import DownloadIcon from '@mui/icons-material/Download';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { DataGrid } from '@mui/x-data-grid';
import Select from '@mui/material/Select';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { ExportToCsv } from 'export-to-csv';
import Firebase from '../Firebase';
import firebase from 'firebase';
import 'firebase/firestore';
import 'firebase/auth'

Firebase.auth().useDeviceLanguage()

window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container');

export default class Admin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            otp: "",
            isLoading: false,
            searchText: "",
            searchLabel: "Search by email",
            searchType: searchTypes[0],
            pageSwitch: "login", // there are three values: "login", "otp", "dashboard"
            flipped: false,
            selectedUser: null,
            userData: [],
            cardData: [],
            selectedCard: null,
        }
        this.user = null
        this.sentOtp = null
        this.usersRef = null
        this.userDataTemp = []
    }

    sendQuery = async () => {

        await Firebase.firestore().collection("USERS").get()
            .then(async (querySnapshot) => {
                querySnapshot.docs.forEach(async (user) => {
                    this.userDataTemp.push({
                        id: user.id,
                        email: user.data().email,
                        date: user.data().time,
                        cards: (await user.ref.collection("CARDS").get()).docs.length
                    })
                })
            })
            .catch((error) => {
                console.log("Could not load users: " + error)
            })

    }

    searchByEmail = async () => {
        try {
            var querySnapshot = Firebase.firestore().collection("USERS").where("email", "==", this.state.searchText).get()
                ; (await querySnapshot).docs.forEach((doc) => {
                    doc.ref.collection("CARDS").get().then((cardsSnapshot) => {
                        this.userQueryResult.push(
                            {
                                id: doc.id,
                                email: doc.data().email,
                                date: doc.data().time,
                                cards: cardsSnapshot,
                            }
                        )
                    })
                })

        }
        catch (error) {
            console.log("Error searching by email: " + error)
        }

    }

    searchByNumberOfCards = async () => {

    }

    searchByTimeCreated = async () => {

    }

    loginView = () => {
        const loginRequest = async () => {
            this.setState({ isLoading: true })

            // 1- Sign in with password and email
            try {
                this.user = await Firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password)
                console.log("logged in user: " + this.user.user.uid)
                await Firebase.auth().signOut().then(() => { console.log("Signed out :" + this.user.user.uid) })
            }
            catch (error) {
                console.log("Failed to login: " + error.message)
                this.setState({ isLoading: false })
                return
            }

            // 2- Get the phone number
            let phone = null
            let userIsAdmin = false
            try {
                let userDoc = await Firebase.firestore().collection("USERS").doc(this.user.user.uid).get()
                userIsAdmin = userDoc.data().admin
                console.log("userIsAdmin: " + userIsAdmin)
                if (userIsAdmin === true) {
                    phone = userDoc.data().phone
                    console.log("Retrieved user phone: " + phone)
                }
                else {
                    throw Error("User is not an admin")
                }
            }
            catch (error) {
                console.log("Failed to retrieve user phone numebr: " + error.message)
                this.setState({ isLoading: false })
                return
            }

            // 3- Send OTP
            try {
                const appVerifier = window.recaptchaVerifier;
                this.sentOtp = await Firebase.auth().signInWithPhoneNumber(phone, appVerifier)
                document.getElementById("recaptcha-container").remove()
                console.log("sent otp: " + this.sentOtp.verificationId)
                this.setState({
                    pageSwitch: "otp",
                    isLoading: false,
                })
                return
            }
            catch (error) {
                console.log("Failed to send OTP: " + error.message)
                this.setState({ isLoading: false })
                return
            }
        }

        return (
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100vh", width: "100%" }}>
                <img src={LogoTransparent}
                    alt={"Omnicard"}
                    style={{ maxHeight: "30%", maxWidth: "30%", marginTop: 20, marginBottom: 50 }}
                />
                <TextField label="Email"
                    onChange={(e) => { this.setState({ email: e.target.value }) }}
                    fullWidth={true}
                    variant="outlined"
                />
                <TextField type="password"
                    onChange={(e) => { this.setState({ password: e.target.value }) }}
                    fullWidth={true}
                    label="Password"
                    variant="outlined"
                />
                <Button variant="contained"
                    id="signin"
                    fullWidth={true}
                    onClick={() => { loginRequest() }}>
                    Login
                </Button>
            </div>
        )
    }

    otpView = () => {
        const confirmOtpRequest = async () => {
            this.setState({ isLoading: true })

            // 1- Confirm the OTP
            try {
                await this.sentOtp.confirm(this.state.otp)
                console.log("Confirmed OTP successfully.")
            }
            catch (error) {
                console.log("Failed to confirm OTP: " + error.message)
                this.setState({ isLoading: false })
                return
            }

            // 2- Log the user in again and change the view
            try {
                this.user = await Firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password)
                this.usersRef = Firebase.firestore().collection("USERS")
                console.log("Successfully confirmed user login: " + this.user.user.uid)
                this.setState({
                    otp: "",
                    isLoading: false,
                    pageSwitch: "dashboard",
                })
            }
            catch (error) {
                console.log("Failed to confirm user login: " + error.message)
                this.setState({ isLoading: false })
                return
            }
        }

        return (
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100vh", width: "100%" }}>
                <Typography variant="h4">
                    Omnicard Card Admin
                </Typography>
                <img src={Logo}
                    alt={"Omnicard"}
                    style={{ maxHeight: "30%", maxWidth: "30%", marginTop: 20, marginBottom: 50 }}
                />
                <TextField label="Enter your one-time password"
                    fullWidth={true}
                    variant="outlined"
                    value={this.state.otp}
                    onChange={(e) => { this.setState({ otp: e.target.value }) }}
                />
                <Button variant="contained"
                    fullWidth={true}
                    onClick={() => { confirmOtpRequest() }}>
                    Confirm
                </Button>
            </div >
        )
    }

    dashboardView = () => {
        const searchTextBox = () => {
            const searchRequest = async () => {
                console.log("Search request: " + this.state.searchType.key + " - " + this.state.searchType.label)
                console.log("Input: " + this.state.searchText)

                this.setState({ isLoading: true })
                await this.sendQuery()
                    .then(() => {
                        this.setState({ isLoading: false })
                    })
            }
            const exportRequest = async () => {
                console.log("Export request")
                const options = {
                    fieldSeparator: ',',
                    quoteStrings: '"',
                    decimalSeparator: '.',
                    showLabels: true,
                    showTitle: true,
                    title: 'omnicard-users-data',
                    useTextFile: false,
                    useBom: true,
                    useKeysAsHeaders: true,
                };
                const csvExporter = new ExportToCsv(options);
                csvExporter.generateCsv(this.state.userData);
            }
            return (
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <TextField label={this.state.searchType.instructions}
                        onChange={(e) => { this.setState({ searchText: e.target.value }) }}
                        fullWidth={true}
                        size="small"
                        variant="outlined"
                    />
                    <Button variant="contained"
                        size="small"
                        onClick={() => { searchRequest() }}>
                        <SearchIcon />
                    </Button>
                    <Button variant="contained"
                        size="small"
                        onClick={() => { exportRequest() }}>
                        <DownloadIcon />
                    </Button>
                </div>
            )
        }
        const searchFilterSelect = () => {
            return (
                <FormControl fullWidth>
                    <Select value={this.state.searchType}
                        onChange={(e) => { this.setState({ searchType: e.target.value }) }}
                    >
                        {
                            searchTypes.map((o) => {
                                return (
                                    <MenuItem value={o}>{o.label}</MenuItem>
                                )
                            })
                        }
                    </Select>
                </FormControl>
            )
        }
        const resultSummary = () => {
            <Typography style={{ margin: 5 }} variant={"subtitle2"}>
                Results found 124
            </Typography>
        }
        const userDataView = () => {
            const userColumns = [
                { field: 'email', headerName: 'Email', width: 200 },
                {
                    field: 'date',
                    headerName: 'Created at',
                    width: 100,
                    valueGetter: (params) => `${new Date(params.row.date * 1000).toLocaleDateString()}`
                },
                {
                    field: 'cards',
                    headerName: 'Cards',
                    width: 60,
                    valueGetter: (params) => `${params.row.cards}`
                }
            ];
            const handleUserSelected = async (selected) => {
                this.setState({ isLoading: true })
                var newCardData = []
                await Firebase.firestore().collection("USERS").doc(selected.id).collection("CARDS").get()
                    .then((userCards) => {
                        userCards.docs.forEach((card) => {
                            newCardData.push({
                                id: card.id,
                                headline: card.data().headline,
                                subheadline1: card.data().subheadline1,
                                subheadline2: card.data().subheadline2,
                                subheadline3: card.data().subheadline3,
                                subheadline4: card.data().subheadline4,

                                backgroundColor: card.data().backgroundColor,
                                headlineColor: card.data().headlineColor,
                                subheadlineColor: card.data().subheadlineColor,

                                logo: card.data().logo,
                                qr: card.data().qr,

                                date: card.data().time,
                                index: card.data().index,
                                category: card.data().category,

                                link: card.data().link,
                            })
                        })
                        this.setState({ cardData: newCardData })
                        this.setState({ isLoading: false })
                    })
                    .catch((error) => {
                        console.log("Could not user cards: " + error)
                    })
                this.setState({ selectedUser: selected.row })
            }
            if (this.state.userData.length > 0) {
                return (
                    <DataGrid
                        rows={this.state.userData}
                        columns={userColumns}
                        pageSize={50}
                        rowsPerPageOptions={[50]}
                        onRowClick={handleUserSelected}
                    />
                )
            }
            else {
                return (
                    <div style={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                        <Typography>
                            No data loaded
                        </Typography>
                        <Typography>
                            Select a query and reload
                        </Typography>
                        <br />
                        <Button variant="contained" onClick={() => { this.setState({ userData: this.userDataTemp }) }}>
                            Reload
                        </Button>
                    </div>
                )
            }
        }
        const cardDataView = () => {
            const cardColumns = [
                { field: 'index', headerName: 'Index', width: 60 },
                { field: 'headline', headerName: 'Headline', width: 120 },
                {
                    field: 'category',
                    headerName: 'Category',
                    width: 120,
                    valueGetter: (params) => `${cardCategories[params.row.category].name}`
                },
                {
                    field: 'date',
                    headerName: 'Created at',
                    width: 100,
                    valueGetter: (params) => `${new Date(params.row.date * 1000).toLocaleDateString()}`
                }
            ];
            const handleCardSelected = async (selected) => {
                this.setState({ selectedCard: selected.row })
            }
            if (this.state.cardData.length > 0) {
                return (
                    <DataGrid
                        rows={this.state.cardData}
                        columns={cardColumns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        onRowClick={handleCardSelected}
                    />
                )
            }
            else {
                return (
                    <div style={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                        <Typography>
                            User does not have any cards
                        </Typography>
                    </div>
                )
            }

        }
        const backToUsersButton = () => {
            const backAction = () => {
                this.setState({
                    selectedUser: null,
                    selectedCard: null
                })
            }
            return (
                <Button variant='contained'
                    size='small'
                    onClick={backAction}
                >
                    <ArrowBackIosIcon /> Back to Users
                </Button>
            )
        }
        const cardDetailsView = () => {
            const cardStyle = {
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: 343,
                height: 212,
                backgroundColor: this.state.selectedCard.backgroundColor,
                borderRadius: 16,
                cursor: "pointer",
                MozTransition: "all 0.5s ease",
                WebkitTransition: "all 0.5s ease",
                msTransition: "all 0.5 ease",
                transition: "all 0.5s ease",
                transformStyle: "preserve-3d",
                transform: this.state.flipped ? "rotateY(180deg)" : "",
            }
            return (
                <div style={{ width: "100vw", paddingTop: 24, display: "flex", flex: 1, alignItems: "center", flexDirection: "column" }}>
                    <Card style={cardStyle}
                        elevation={5}
                        onClick={() => { this.setState({ flipped: !this.state.flipped }) }}>
                        {
                            this.state.flipped === false
                            &&
                            <CardContent style={{ display: "flex", flex: 1, flexDirection: "row", justifyContent: "space-between", }}>
                                <div style={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center" }}>
                                    <img src={this.state.selectedCard.logo}
                                        alt={"Omnicard"}
                                        style={{ maxHeight: "70%", maxWidth: "70%" }} />
                                </div>
                                <div style={{ display: "flex", flex: 1, flexDirection: "column", justifyContent: "center" }}>
                                    <Typography variant={"h5"} style={{ color: this.state.selectedCard.headlineColor }}>
                                        {this.state.selectedCard.headline}
                                    </Typography>
                                    <Typography variant={"subtitle2"} style={{ color: this.state.selectedCard.subheadlineColor }}>
                                        {this.state.selectedCard.subheadline1 !== "" && this.state.selectedCard.subheadline1}
                                    </Typography>
                                    <Typography variant={"subtitle2"} style={{ color: this.state.selectedCard.subheadlineColor }}>
                                        {this.state.selectedCard.subheadline2 !== "" && this.state.selectedCard.subheadline2}
                                    </Typography>
                                    <Typography variant={"subtitle2"} style={{ color: this.state.selectedCard.subheadlineColor }}>
                                        {this.state.selectedCard.subheadline3 !== "" && this.state.selectedCard.subheadline3}
                                    </Typography>
                                    <Typography variant={"subtitle2"} style={{ color: this.state.selectedCard.subheadlineColor }}>
                                        {this.state.selectedCard.subheadline4 !== "" && this.state.selectedCard.subheadline4}
                                    </Typography>
                                </div>
                            </CardContent>
                        }
                        {
                            this.state.flipped === true
                            &&
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", transform: "rotateY(180deg)" }}>
                                <img src={this.state.selectedCard.qr}
                                    alt={"Omnicard"}
                                    style={{ height: 212, width: 212 }} />
                            </div>
                        }
                    </Card>
                    <Button href={this.state.selectedCard.link}
                        target="_blank"
                        style={{ marginTop: 24 }}
                    >
                        Open Content
                    </Button>
                </div>
            )
        }
        const backToCardsButton = () => {
            const backAction = () => {
                this.setState({
                    selectedCard: null
                })
            }
            return (
                <Button variant='contained'
                    size='small'
                    onClick={backAction}
                >
                    <ArrowBackIosIcon /> Back to Cards
                </Button>
            )
        }
        return (
            <div style={{ display: "flex", flexDirection: "column", height: "100vh", width: "100%" }}>
                {searchTextBox()}
                {searchFilterSelect()}
                {resultSummary()}
                {this.state.selectedUser === null && userDataView()}
                {this.state.selectedUser !== null && this.state.selectedCard === null && cardDataView()}
                {this.state.selectedUser !== null && this.state.selectedCard !== null && cardDetailsView()}
                {this.state.selectedUser !== null && this.state.selectedCard === null && backToUsersButton()}
                {this.state.selectedUser !== null && this.state.selectedCard !== null && backToCardsButton()}
            </div>
        )
    }

    render() {
        if (this.state.isLoading) {
            return (
                <div style={{ display: "flex", height: "100vh", justifyContent: "center", alignItems: "center" }}>
                    <CircularProgress />
                </div>
            )
        }
        else {
            if (this.state.pageSwitch === "login") {
                return (this.loginView())
            }
            else if (this.state.pageSwitch === "otp") {
                return (this.otpView())
            }
            else if (this.state.pageSwitch === "dashboard") {
                return (this.dashboardView())
            }
        }
    }
}

const searchTypes = [
    {
        key: 0,
        label: "Search by email",
        instructions: "Characters contained in the email"
    },
    {
        key: 1,
        label: "Users with at least x number of cards",
        instructions: "Number of cards"
    },
    {
        key: 2,
        label: "Users created within x days ago",
        instructions: "Number of days since account was created"
    },
]

let cardCategories = [
    {
        name: "Twitter",
        instructions: "Twitter username without the @",
        example: "elonmusk",
        image: require("../Assets/Icons/twitter.png")
    },
    {
        name: "Instagram",
        instructions: "Instagram username without the @",
        example: "dualipa",
        image: require("../Assets/Icons/instagram.png")
    },
    {
        name: "Facebook",
        instructions: "Facebook URL",
        example: "https://www.facebook.com/google/",
        image: require("../Assets/Icons/facebook.png")
    },
    {
        name: "Linkedin",
        instructions: "Linkedin URL",
        example: "https://www.linkedin.com/in/christinelagarde/",
        image: require("../Assets/Icons/linkedin.png")
    },
    {
        name: "WhatsApp",
        instructions: "WhatsApp Number",
        example: "+18002752273",
        image: require("../Assets/Icons/whatsapp.png")
    },
    {
        name: "GitHub",
        instructions: "Github username",
        example: "spotify",
        image: require("../Assets/Icons/github.png")
    },
    {
        name: "YouTube",
        instructions: "YouTube Channel URL",
        example: "https://www.youtube.com/c/ChubbyemuGames",
        image: require("../Assets/Icons/youtube.png")
    },
    {
        name: "Snapchat",
        instructions: "Snapchat username",
        example: "KevinHart4real",
        image: require("../Assets/Icons/snapchat.png")
    },
    {
        name: "TikTok",
        instructions: "TikTok username without the @",
        example: "realwhitneycummings",
        image: require("../Assets/Icons/tiktok.png")
    },
    {
        name: "Soundcloud",
        instructions: "Soundcloud username",
        example: "cosmicatx",
        image: require("../Assets/Icons/soundcloud.png")
    },
    {
        name: "Spotify",
        instructions: "Spotify URL",
        example: "https://open.spotify.com/artist/6T5tfhQCknKG4UnH90qGnz",
        image: require("../Assets/Icons/spotify.png")
    },
    {
        name: "Pinterest",
        instructions: "Pinterest username without the @",
        example: "behance",
        image: require("../Assets/Icons/pinterest.png")
    },
    {
        name: "Phone",
        instructions: "Phone number",
        example: "+18002752273",
        image: require("../Assets/Icons/phone.png")
    },
    {
        name: "Link",
        instructions: "Any Link",
        example: "https://firebase.google.com/products-release",
        image: require("../Assets/Icons/link.png")
    },
    {
        name: "File",
        instructions: "File Link",
        example: "https://files.mywebsite.com/docs/resume.pdf",
        image: require("../Assets/Icons/file.png")
    },
    {
        name: "Contact",
        instructions: "Contact Card Link",
        example: "https://files.mywebsite.com/docs/contact.vcf",
        image: require("../Assets/Icons/contact.png")
    }
]